import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";

// This is a page to view a single job role and the info on it

const Careers = ({ data }) => {
	console.log("Job role data", data);

	const { title, content } = data.post;

	return (
		<Layout>
			<div>
				{/* A image could go here */}
				<div>{title}</div>
				<div dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		</Layout>
	);
};

export default Careers;

export const pageQuery = graphql`
	query ($id: String!) {
		post: wpCareer(id: { eq: $id }) {
			id
			title
			content
		}
	}
`;
